import classes from './Omne.module.css'
import profilePhotoWebp from './../../img/profile-v2.webp'; 
import profilePhotoPng from './../../img/profile-v2.png'; 

const Omne = () => {
    return ( 
        <div className = {classes.Omne} id="Omne">
            <div className = {classes.content}>
                <h1>Dobrý deň!</h1>
                <div  className = {classes.podNadpisom} >
                    <div className = {classes.podNadpisomVlavo} >
                        <p>Moje meno je Lucia Hlístová <span style={{fontWeight: 'bold'}}>a viem Vám pomôcť</span> s tým, čo Vás trápi, čo prežívate. Som <span style={{fontWeight: 'bold'}}>psychologička</span> a <span style={{fontWeight: 'bold'}}>koučka</span>. Mám za sebou niekoľko rokov práce s obeťami násilia všetkých druhov a stále sa venujem aj práci s ľuďmi v krízových situáciách. Zároveň profesionálne sa venujem aj koučingu, ktorý slúži na to, aby ste sa vo svojich životoch rozvíjali a posúvali k svojím vytúženým cieľom.<br/> <span style={{fontWeight: 'bold'}}>Chcem tu byť</span> aj pre Vás či už v tom, čo Vás <span style={{fontWeight: 'bold'}}>trápi</span> alebo byť pri Vašom <span style={{fontWeight: 'bold'}}>osobnostnom raste</span> na Vašej jedinečnej ceste životom. Na to, aby ste mohli začať Vašu cestu k tomu, aby Vám mohlo byť opäť lepšie alebo aby ste sa posunuli vo Vašom živote, stačia <span style={{fontWeight: 'bold'}}>štyri jednoduché kroky:</span></p>
                        <ol>
                            <li><p style={{display:"inline"}}>Zoberte si mobil</p></li>
                            <li><p style={{display:"inline"}}>Zatelefonujte na tel. číslo: +421 949 650 049 / napíšte email na: lanavihlistova@gmail.com</p></li>
                            <li><p style={{display:"inline"}}>Dohodnite si so mnou konkrétny termín</p></li>
                            <li><p style={{display:"inline"}}>Prídite :)</p></li>
                        </ol>
                    </div>
                    <picture>
                        <source type="image/avif" srcSet={profilePhotoWebp} />
                        <source type="image/png" srcSet={profilePhotoPng} />
                        <img src={profilePhotoPng} alt="Logo" />
                    </picture>
                </div>
            </div>
        </div> 
    );
}
 
export default Omne;