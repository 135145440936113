import classes from './InstagramFeed.module.css';
const InstagramFeed = () => {

    // const [instagramData, setInstagramData] = useState([]);
    // const accessToken = 'EAASyYWMwZC1sBOxagydb3hdJvuGKEmKEBKkBCoE7NKWajPW0S0QHKixZCt3n6wApApKZBbLT6vTdJavHd25OV1aNSggvWMzr1oA6pWPRdWnQS5lQPnxIxIAfJBZCGPDA2ZADkkB6efhZAp7G6RqKIBajlvCE42NF6uYFoAjCYkUjOQhZBjLbjn9ZBosR';
    // useEffect(() => {
    //     const getInstagramFeed = async () => {
    //       try {
    //         const response = await fetch(`https://graph.instagram.com/v18.0/me/media?fields=id,caption,media_type,media_url,thubnail_url,permalink,timestamp&access_token=${accessToken}`);

    //         // const response = await fetch(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${accessToken}`);
    //         const data = await response.json();
    //         setInstagramData(data.data.slice(0, 8));
    //       } catch (error) {
    //       }
    //     };
    
    //     getInstagramFeed();
    //   }, [accessToken]);
    


    return ( 
        <div className = {classes.content}>
            <h1>Instagram <a className={classes.IgOdkaz} href="https://www.instagram.com/_.lanavi._/">@_.lanavi._</a></h1>
            {/* <div className = {classes.InstagramFeed}>
                {instagramData.map(post => (
                    <a key={post.id} href={post.permalink} target="_blank" rel="noopener noreferrer">
                    {post.media_type === 'VIDEO' ? (
                        <video controls disablePictureInPicture controlslist="nodownload noplaybackrate">
                            <source src={post.media_url} type="video/mp4" />
                        </video>
                    ) : (
                        <img src={post.media_url} alt={"fotka z instagramu"}/>
                    )}
                    </a>
                ))}
            </div> <div className = {classes.InstagramFeed}>
                {instagramData.map(post => (
                    <a key={post.id} href={post.permalink} target="_blank" rel="noopener noreferrer">
                    {post.media_type === 'VIDEO' ? (
                        <video controls disablePictureInPicture controlslist="nodownload noplaybackrate">
                            <source src={post.media_url} type="video/mp4" />
                        </video>
                    ) : (
                        <img src={post.media_url} alt={"fotka z instagramu"}/>
                    )}
                    </a>
                ))}
            </div>  */}
        </div>
    );
}
 
export default InstagramFeed;